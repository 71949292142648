/* Universal CSS */
*{
    box-sizing:border-box;
  }
  
  /* CSS Variables */
  :root{
    --main-text-color:#333333;
    --yellow:#fdb723;
    --yellow-hover:#eea811;
    --red:#e51414;
    --almost-white:#f7f7f9;
  }
  
  .red-text{
    color:#e51514;
  }
  
  
  /* High-Level Body Styling */
  body{
    font-family:'Roboto', Arial, Helvetica, sans-serif;
    font-size:15px;
    color: var(--main-text-color);
    margin:0;
    padding:0;
    display:flex;
    flex-direction: column;
    min-height:100vh;
  }
  
  #root{
    display:flex;
    flex-direction: column;
    min-height:100vh;
    justify-content: space-between;
  }
  
  .hide{display:none;}
  
  /* Basic Formatting */
  .mt5{margin-top:5px !important;}
  .mt8{margin-top:8px !important;}
  .mt10{margin-top:10px !important;}
  .mt12{margin-top:12px !important;}
  .mt15{margin-top:15px !important;}
  .mt18{margin-top:18px !important;}
  .mt20{margin-top:20px !important;}
  .mt25{margin-top:25px !important;}
  .mt30{margin-top:30px !important;}
  .mt40{margin-top:40px !important;}

  .mb5{margin-bottom:5px !important;}
  .mb10{margin-bottom:10px !important;}
  .mb20{margin-bottom:20px !important}
  .mb30{margin-bottom:30px !important}
  .mb40{margin-bottom:40px !important}
  
  .padLeft10{padding-left:10px;}
  
  .pad10{padding:10px;}
  .pad20{padding:20px;}

  .hide{display:none;}
  
  a, a:active, a:visited{
    color:var(--main-text-color);
  }


.primary-button{
  padding:15px 0;
  width:215px;
  margin:0 8px;
  font-size:14px;
  font-weight:600;
  border:none;
  border-radius:10px;
  outline:none;
  text-align:center;
  text-decoration:none;
  text-transform:uppercase;
  display:inline-block;
  transition: all 0.3s ease 0s;
}

.secondary-button{
  padding:10px 0;
  width:135px;
  margin:0 8px;
  font-size:14px;
  font-weight:600;
  border:none;
  border-radius:10px;
  outline:none;
  text-align:center;
  text-decoration:none;
  text-transform:uppercase;
  display:inline-block;
  transition: all 0.3s ease 0s;
}

.primary-button{background-color:var(--yellow);}
.primary-button:hover{background-color:var(--yellow-hover);cursor:pointer;}

.secondary-button{background-color:#d8d8d8;}
.secondary-button:hover{background-color:#cacaca;cursor:pointer;}

  .main-content a{
    text-decoration:none;
   }

  .main-content a:hover{
    color:var(--yellow);
  }
  
  .center{text-align:center;}
  .right{text-align:right;}

  .num-results{
    //margin-top:10px;
    padding-left:10px;
  }

  .expand{
    padding: 6px 14px;
    width: 38px;
    border-radius: 10px;
    background-color: var(--yellow);
    color: #FFF;
    font-size: 20px;
    float:right;
  }

  .page-links{
    list-style-type:none;
    margin:0;
    padding:0;
  }

  .page-links li{
    font-size:17px;
    margin:10px auto;
    max-width:250px;
    //display:inline-block;
    // margin-bottom:10px;
    //padding:10px;
    //border:1px solid #efefef;
    //border-radius:10px;
    //width:275px;
    //background-color:#f1f1f1;
  }

  .page-links li:hover{
    //background-color:#e7e7e7;
  }

  .page-links li a{
    color: #606060;
    text-decoration:none;
  }

  .page-links li a:hover{
    color:var(--yellow-hover);
    /*text-decoration:underline;*/
    //color:#292929;
  }

  .div-divider{
    max-width:1200px;
    margin:10px auto;
    border-bottom:1px solid #CCC;
  }
  
  h1{
    margin:0;
    padding:0;
    font-size:32px;
    text-align:center;
  }
  
  h2{
    margin:0;
    padding:0;
    font-size:21px;
    font-weight:600;
    text-transform:none;
    text-align:center;
    color:#626262;
    //color:#d7ab0a;
  }
  
  h3{
    margin:0;
    padding:0;
    font-size:18px;
    font-weight:500;
    text-transform:none;
  }
  
  .align-top{
    align-items:flex-start !important;
  }
  
  .thin-divider{
    width:100%;
    margin:20px auto;
    border-bottom:1px solid #e1e1e1;
  }

/* Main Styling */
.main{
    width:100%;
    margin-top:20px;
    flex: 1;
  }
  
  .main-content{
    max-width:1400px;
    margin:0 auto;
    padding:0 20px;
  }
  
  .welcome-text{
    padding:0 0 0 5px;
    border-left:4px solid var(--yellow);
    font-size:15px;
    font-weight:500;
    margin:0 auto;
  }
  /* End of Main Styling */
  

/* Header Content */
header{
    width:100%;
    text-transform:uppercase;
    border-bottom:1px solid #cfd0d4;
  }
  
  .header-content{
    max-width:1400px;
    margin:0 auto;
    padding:20px;
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  
  .header-content ul{
    list-style-type:none;
    margin:0;
    padding:0;
    display:inline-block;
  }
  
  .header-content ul li{
    display:inline-block;
    padding:0 10px;
  }
  
  .header-content ul li a{
    position:relative;
    padding:2px 0;
    text-decoration:none;
    font-weight:500;
  }

  .header-content ul li a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--yellow);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s ease;
  }
  
  .header-content ul li a:hover::before {
    transform: scaleX(1);
  }
  
  .flex-item{
    //flex: 1 33%;
    line-height:22px;
    //max-width:350px;
  }

  .flex-item:first-of-type{
    flex: 1 25%;
  }

  .flex-item:nth-of-type(2){
    flex: 1 50%;
  }
  
  .flex-item:last-of-type{
    flex: 1 25%;
    text-align:right;
  }
  
  .header-title{
    font-weight: 600;
    font-size: 28px;
    text-transform: none;
    color: #727272;
    //color:#e5b326;
    color:#6a6a6a;
    // padding-left:55px;
  }
  /* End of Header Content */

  /* Footer Content */
footer{
    width:100%;
    margin-top:40px;
    padding:40px;
    background-color:#282828;
    color: var(--almost-white);
    font-size:14px;
    font-weight:400;
  }
  
  .footer-content{
    max-width:1400px;
    margin:0 auto;
  }
  
  .footer-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-logo{
    height:28px;
  }
  
  .footer-nav{
    list-style-type:none;
    margin:0;
    padding:0;
    font-size:14px;
  }
  
  .footer-nav li{
    margin-left:30px;
    display:inline-block;
  }
  
  .footer-nav li a{
    position:relative;
    padding:4px 0;
    text-decoration:none;
    font-weight:600;
    color:#FFF;
  }
  
  .footer-nav li a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--yellow);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s ease;
  }
  
  .footer-nav li a:hover::before {
    transform: scaleX(1);
  }
  
  .footer-nav li:last-child a{
    color:var(--yellow);
  }
  
  .footer-heading{
    color:#FFF;
    font-weight:600;
  }
  
  .footer-copy{
    font-size:13px;
    margin-top:10px;
  }
  
  .footer-copy a{
    color:#FFF;
  
  }
  
  .footer-copy a:hover{
    color:#b8b8b8;
  }
  
  .social-container{
    text-align:right;
  }
  
  .social-icon{
    display:inline-block;
    margin-left:10px;
    transition: all .2s ease-in-out; 
  }
  
  .social-icon:hover{
    transform: scale(1.3); 
  }
  /* End of Footer Content */

  .release-note{
    font-size:15px;
    padding:25px 0 25px 0;
    border-bottom:1px solid #CCC;
  }

  .release-note a{
    color:var(--yellow);
  }

  .release-note a:hover{
    text-decoration:underline;
  }

  .release-note:last-of-type{
    border:none;
  }

  .release-note strong{
    font-weight:500;
  }

  .rn-title{
    font-size:18px;
    font-weight:500;
  }

  .rn-desc{
    line-height:22px;
  }

  .flex-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 0 0 8px 0;
    // border-left: 1px solid #CCC;
    // border-top: 1px solid #CCC;
    // border-right: 1px solid #CCC;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
  }

  .flex-wrapper div, .flex-wrapper h2{
    flex:1 33%;
  }

  .extra-info{
    margin-top:5px;
    text-align:center;
    color:rgb(209 149 19);
    font-size:14px;
  }


  table{
    table-layout:auto;
    width:100%;
    margin:0 auto;
    border-collapse:separate;
    border:none;
    border-top:1px solid #dfdfdf;
  }

  th{
    padding:12px 10px;
    text-align:left;
    border-bottom:2px solid #7a7a7a;
    color:#838383;
    color:#000;
    font-weight: 500;
    font-size:13px;
  }

//   th:first-of-type, th:last-of-type{border-left:1px solid #CCC;}

  tbody tr:hover{
    background-color:#f3f3f3;
    cursor:pointer;
  }
  
  th:hover{
    background-color:#f3f3f3;
    cursor:pointer;
  }

  td{
    // height:40px;
    padding:0 10px;
    border-bottom:1px solid #dfdfdf;
    position:relative;
    height:46px;
    font-size:14px;
  }

  .tracking-table th:nth-child(1){width:7%;text-align:left;} // Jira ID
  .tracking-table th:nth-child(2){width:23%;text-align:left;} // Title
  .tracking-table th:nth-child(3){width:32%;text-align:left;} // Description
  .tracking-table th:nth-child(4){width:10%;text-align:left;} // Use
  .tracking-table th:nth-child(5){width:8%;text-align:left;} // Updated
  .tracking-table th:nth-child(6){width:10%;text-align:center;} // Status

  .pending-table th:nth-child(1){width:7%;text-align:left;} // Jira ID
  .pending-table th:nth-child(2){width:25%;text-align:left;} // Title
  .pending-table th:nth-child(3){width:32%;text-align:left;} // Description
  .pending-table th:nth-child(4){width:10%;text-align:left;} // Use
  .pending-table th:nth-child(5){width:8%;text-align:left;} // Updated
  .pending-table th:nth-child(6){width:8%;text-align:left;} // Due
  .pending-table th:nth-child(7){width:10%;text-align:center;} // Status

  .review-table th:nth-child(1){width:7%;text-align:left;} // Jira ID
  .review-table th:nth-child(2){width:25%;text-align:left;} // Title
  .review-table th:nth-child(3){width:32%;text-align:left;} // Description
  .review-table th:nth-child(4){width:10%;text-align:left;} // Use
  .review-table th:nth-child(5){width:8%;text-align:left;} // Updated
  .review-table th:nth-child(6){width:8%;text-align:left;} // Due
  .review-table th:nth-child(7){width:10%;text-align:center;} // Status

  .other-table th:nth-child(1){width:7%;text-align:left;} // Jira ID
  .other-table th:nth-child(2){width:25%;text-align:left;} // Title
  .other-table th:nth-child(3){width:32%;text-align:left;} // Description
  .other-table th:nth-child(4){width:10%;text-align:left;} // Use
  .other-table th:nth-child(5){width:8%;text-align:left;} // Updated
  .other-table th:nth-child(6){width:8%;text-align:left;} // Due
  .other-table th:nth-child(7){width:10%;text-align:center;} // Status

  /* Center the Status Column */
  .tracking-table tbody tr td:nth-child(6){text-align:center;}
  .tracking-table tbody tr td:nth-child(6) div{justify-content:center;}

  .pending-table tbody tr td:nth-child(7){text-align:center;}
  .pending-table tbody tr td:nth-child(7) div{justify-content:center;}

  .review-table tbody tr td:nth-child(7){text-align:center;}
  .review-table tbody tr td:nth-child(7) div{justify-content:center;}

  .other-table tbody tr td:nth-child(7){text-align:center;}
  .other-table tbody tr td:nth-child(7) div{justify-content:center;}
  /* End of Status Column Center */

  /* Jira Link - Only show on mobile */
  .tracking-table th:nth-of-type(7), .tracking-table td:nth-of-type(7){
    display:none;
  }

  .pending-table th:nth-of-type(8), .pending-table td:nth-of-type(8){
    display:none;
  }

  .review-table th:nth-of-type(8), .review-table td:nth-of-type(8){
    display:none;
  }

  .other-table th:nth-of-type(8), .other-table td:nth-of-type(8){
    display:none;
  }
  /* End of Jira Link */

  /* The sticky class is added to the navbar with JS when it reaches its scroll position */
  .sticky{
    position:sticky;
    top: -1px;
    // width: 100%;
    background-color:#ffffff;
    z-index:1;
  }

  .sticky th{
    //border-bottom:2px solid #7a7a7a !important;
  }
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .main {
    padding-top: 60px;
  }

  input, select{
    padding: 10px;
    border-radius:10px;
    outline:none !important;
    transition: all 0.3s ease;
    font-family:'Roboto', Arial, Helvetica, sans-serif;
    font-size:13px;
  }
  
  input[type="text"], input[type="password"], input[type="email"], input[type="date"], textarea{
    width:100%;
    border:1px solid #d1d1d1;
    // font-size:15px;
    color:#535353;
  }

  input[type="text"]::placeholder{
    color:rgb(121, 121, 121);
    font-size:14px;
  }
  
  input:focus, select:focus{
    outline:none !important;
    border:1px solid var(--yellow);
  }

  .search-wrapper{
    max-width:965px;
    margin:40px auto 10px auto;
    text-align:center;
  }
  
  .search-wrapper input{
    width:225px;
    text-align:left;
    padding-right:35px;
  }
  
  .clear-search{
    display:none;
    position: relative;
    top: 0;
    left: -30px;
    padding: 0;
    padding:1px 8px 3px 8px;
    color: #b3b3b3;
    font-weight: 500;
    font-size: 16px;
    cursor:pointer;
    transition: all 0.3s ease 0s;
    background-color:#e1e1e1;
    border-radius:20px;
    color:#FFF;
    
  }
  
  .clear-search:hover{
    background-color:#c6c6c6;
  }

  .requests-search{
    transition: all 0.5s ease 0s;
  }

  /*  Modal Styling */
#overlay, #loading-overlay {
    position: fixed; /* Stay in place */
    visibility: hidden;
    z-index: 9998;
    opacity:0;
    //opacity: 0.8;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0, .8);
    transition: all 0.3s;
  }

  #overlay.show, #loading-overlay.show {
    opacity: 0.8;
    visibility: visible;
    z-index: 100;
  }

  // Loading Spinner
.deadCenter{
    position: fixed; /* or absolute */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  // End of Loading Spinner


  .return-top{
    display:none;
    position:fixed;
    bottom:25px;
    right:calc(50% - 475px);
    padding:8px 15px;
    font-size:15px;
    background-color:var(--yellow);
    border-radius:20px;
    color: var(--main-text-color);
    cursor:pointer;
    z-index:1;
    transition: all 0.3s ease 0s;
  }
  
  .return-top:hover{
    background-color:var(--yellow-hover);
  }

// Collapse a master request
.expand-down {
    width: 0;
    height: 0;
    // Right Arrow
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 9px solid #7a7a7a;
    //border-left: 9px solid var(--yellow);
    display: inline-block;
    margin: 0 0 0 3px;
    transform: rotate(0deg);
    transition:transform 0.4s;
  }
  
  // Expand a master request
  .expand-up {
    width: 0;
    height: 0;
    // Right Arrow
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 9px solid #7a7a7a;
    //border-left: 9px solid var(--yellow);
    display: inline-block;
    margin: 0 0 0 3px;
    transform: rotate(90deg);
    transition:transform 0.4s;
  }

  .master-highlight{
    background-color:#e7e7e7;
    //box-shadow: inset 2px 0px 0px 0 #c4c4c4;
    // box-shadow: inset 2px 0px 0px 0 #f3d06f;
  }
  .master-highlight:hover{
    background-color:#e0e0e0;
  }

  .slave-highlight{
    // background-color:#f9f9f9;
    // box-shadow: inset 2px 0px 0px 0 #ffd57d;
    
    // border-left:2px solid #fff0d1;
  }
  
  .slave-highlight td{
    height:0 !important;
    max-height:0 !important;
    border-collapse:collapse;
    border:none;
  }
  
  .slave-highlight td div {
    height:0;
    //max-height:0;
    //padding:0;
    opacity:0.7;
    box-sizing: border-box;
    overflow:hidden;
    transition: height 0.25s ease-out;
    //transition: max-height 0.25s ease-out;
  }
  
  .slave-highlight.active td div {
    opacity:1;
    height: 70px;
    //max-height:180px;
    display:flex;
    align-items: center;
    line-height:20px;
    transition: height 0.45s, opacity 0.50s ease-in;
    //transition: max-height 0.45s, opacity 0.50s ease-in;
  }
  
  .slave-highlight.active td{
    border-bottom:1px solid #dfdfdf;
  }
  
  .slave-highlight.active td:nth-of-type(6) div{justify-content:center;}
  .slave-highlight.active td:nth-of-type(7) div{justify-content:center;}

  

  .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid var(--yellow);
    display:inline-block;
    margin:0 0 0 4px;
  }
  
  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid var(--yellow);
    display:inline-block;
    margin:0 0 0 4px;
  }


@media screen and (max-width: 825px){
  table{
    width:100%;
    border: 0;
    border-top:none !important;
  }

    table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    display: block;
    margin-bottom: 16px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 2px 5px 5px 5px;
    border-bottom: 4px solid #dfdfdf;
  }

  table tr:first-of-type{
    display:none;
  }

  table td {
    border-bottom: 1px solid #e5e5e5;
    font-size: 12px;
    text-align: right !important;
    padding:10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display:block;
    max-height:auto !important;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: 500;
    text-transform: uppercase;
    color: #727272;
    text-align:left;
    width:35%;
  }

  table td div{
    height:auto;
    visibility:visible;
    display:block !important;
  }

  .slave-highlight td{
    line-height:20px;
    height:auto !important;
    max-height:75px !important;
    border-bottom: 1px solid #e5e5e5;
  }

  .slave-highlight td:nth-of-type(3){
    max-height:140px !important;
  }

  .slave-highlight td div{
    height:auto;
    opacity:1;

  }
  
  table td:last-child {
    border-bottom: 0;
    padding-bottom:3px;
    
  }

  table td:last-child a, table td:last-child a:hover{
    text-decoration:None;
  }

  .tracking-table td:nth-of-type(7), .pending-table td:nth-of-type(8), .review-table td:nth-of-type(8), .other-table td:nth-of-type(8){
    display:block;
    text-align:center !important;
    font-size:15px;
  }

  // table td:nth-of-type(6){
  //   display:block;
  //   text-align:center !important;
  //   font-size:15px;
  // }

  // table td:nth-of-type(6) a{
  //   text-decoration:None;
  // }

  // table td:nth-of-type(6) a:hover{
  //   text-decoration:none;
  // }

  .search-wrapper{
    width:98%;
  }

  .header-content{
    display:block;
    text-align:center;
    ul{
      display:block;
      margin-top:15px;
      text-align:center;
    }
    .header-title{
      margin-top:15px;
      padding-left:0;
      font-size:24px;
      line-height:30px;
    }
  }

  .flex-wrapper div:last-of-type{
    //display:none;
  }

  .flex-item:last-of-type{
    //flex: 1 25%;
    text-align:center;
    margin-top:10px;
  }
}




@media screen and (max-width: 965px){
    .return-top{
        bottom:25px;
        right:calc(50% - 69px);
        padding:8px 15px;
        font-size:15px;
        background-color:var(--yellow);
        border-radius:20px;
        color: #FFF;
        cursor:pointer;
        z-index:1;
    }
    
    .footer-row{
        display:block;
    }

    .footer-nav{
        display:block;
        font-size:17px;
        margin-top:10px;
    }

    .footer-nav li{
        display:block;
        margin-left:0;
        padding:6px 0;
    }

    .social-wrapper{
        margin-top:15px;
    }

    .social-container{
        text-align:left;
    }

    .social-icon{
        margin:0 10px 0 0;
    }
}

@media screen and (max-width: 1225px){
  .slave-highlight.active td div {
    height: 90px;
  }
}

@media screen and (max-width: 965px){
  .slave-highlight.active td div {
    height: 120px;
  }
}